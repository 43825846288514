export class UserCredentials {
  Email: string;
  Password: string;
  PasswordCheckbox: string;
  constructor() {
    this.Email = "";
    this.Password = "";
    this.PasswordCheckbox = "";
  }
}

export class User {
  Id: string;
  Email: string;
  Username: string;
  Name: string;
  DateNBF: Date; // not before
  DateIAT: Date; // Issued at
  DateEXP: Date; // Experation
  DateAuthTime: Date; //
  Roles: string[] = [];
  Permissions: string[] = [];
  constructor() {
    this.Id = "";
    this.Email = "";
    this.Username = "";
    this.Name = "";
    this.Roles = [];
    this.Permissions = [];
  }
}
