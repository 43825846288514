import { Injectable } from "@angular/core";
import { StorageMap } from "@ngx-pwa/local-storage";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor(private storage: StorageMap) {}

  get(key: string): any {
    try {
      // Local storage
      const objectJson = localStorage.getItem(key);
      if (objectJson) {
        const object = JSON.parse(objectJson);
        return object;
      }
    } catch (error) {
      console.log("!!! remove key?" + key);
      localStorage.removeItem(key);
    }

    return null;
  }

  set(key: string, value: any): void {
    // Local storage
    if (value === null) {
      this.remove(key);
      return;
    }
    const objectJson = JSON.stringify(value);
    localStorage.setItem(key, objectJson);
  }

  remove(key: string): void {
    // Local storage
    localStorage.removeItem(key);
  }

  clear(): void {
    let userVal: string = localStorage.getItem("email");
    let passVal: string = localStorage.getItem("password");
    // Local storage
    localStorage.clear();
    localStorage.setItem("email", userVal);
    localStorage.setItem("password", passVal);
  }
}
