import { IsAuthenticatedGuard } from "./account/shared/auth.guards";
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "sign-in",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  { path: "login", redirectTo: "sign-in", pathMatch: "full" },
  // {
  //   path: "Layout",
  //   canActivate: [IsAuthenticatedGuard],
  //   loadChildren: () =>
  //     import("./modules/layout/layout.module").then((m) => m.AppLayoutModule),
  // },
  {
    path: "location-description-list",
    canActivate: [IsAuthenticatedGuard],
    loadChildren: () =>
      import(
        "./modules/location-description-list/location-description-list.module"
      ).then((m) => m.LocationDescriptionListModule),
  },
  {
    path: "location-description-create",
    canActivate: [IsAuthenticatedGuard],
    loadChildren: () =>
      import(
        "./modules/location-description-create/location-description-create.module"
      ).then((m) => m.LocationDescriptionCreateModule),
  },
  {
    path: "admin",
    canActivate: [IsAuthenticatedGuard],
    loadChildren: () =>
      import("./modules/admin/admin.module").then((m) => m.AdminModule),
  },
  // {
  //   path: "",
  //   canActivate: [IsAuthenticatedGuard],
  //   loadChildren: () =>
  //     import("./modules/layout/layout.module").then((m) => m.AppLayoutModule),
  // },
  {
    path: "location-description-update",
    loadChildren: () =>
      import(
        "./modules/location-description-update/location-description-update.module"
      ).then((m) => m.LocationDescriptionUpdateLayoutModule),
  },
  {
    path: "",
    canActivate: [IsAuthenticatedGuard],
    loadChildren: () =>
      import(
        "./modules/location-description-list/location-description-list.module"
      ).then((m) => m.LocationDescriptionListModule),
  },
  {
    path: "dev",
    loadChildren: () =>
      import("./modules/dev/dev.module").then((m) => m.ComponentsModule),
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./modules/pages/pages.module").then((m) => m.PagesModule),
  },
  // no lazy loading
  // {
  //   path: "",
  //   component:Layoutcomponent,
  //   children: [
  //     {
  //       path: "theme",
  //       loadChildren: () =>
  //         import("./modules/components/components.module").then(
  //           (m) => m.ComponentsModule
  //         ),
  //     },
  //   ],
  // },
  { path: "**", redirectTo: "sign-in", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
