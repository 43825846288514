import * as _ from "lodash-es";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { ItemGroup } from "../models/itemgroup.model";
import { Room } from "../models/room.model";
import { LocationDescriptionListComponent } from "src/app/modules/location-description-list/location-description-list.component";
import { LocationDescription } from "../models/locationdescription.model";
import { Logger } from "../logger/logger";
import { MessageService } from "../services/message.service";
import { DataService } from "../services/data.service";
import { Router } from "@angular/router";
import { StorageMap } from "@ngx-pwa/local-storage";
import { map, tap } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { AuthService } from "src/app/account/shared/auth.service";
import * as moment from "moment";
import { Console, error } from "console";
import { UserLog } from "../models/userlog.model";

@Injectable({
  providedIn: "root",
})
export class StateStore implements OnDestroy {
  private subs: Subscription[] = [];
  private sub_io: Subscription; // isOnline
  private sub_ls_gld: Subscription; //LocalStorage get location descriptions
  private sub_ls_sld: Subscription; //LocalStorage Set Location Descriptions
  private sub_ds_gld: Subscription; //DataService get location description
  private sub_ls_grp: Subscription; //DataService get location description
  private sub_ls_gip: Subscription; //DataService get location description
  private sub_ds_grp: Subscription; //DataService get room prototypes
  private sub_ds_gip: Subscription; //DataService  get group prototypes
  // plain values no observables
  public isOnlineFlag: boolean;
  public showProgressbar: boolean;
  // settings$: BehaviorSubject<Setting[]> = new BehaviorSubject<Setting[]>(null);
  // public settings$: BehaviorSubject<ItemGroup[]> = new BehaviorSubject<Setting[]>(
  //   null
  // );
  public isInitializedFlag$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public debugFlag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  // =======================
  // prototypes
  public itemGroups$: BehaviorSubject<ItemGroup[]> = new BehaviorSubject<
    ItemGroup[]
  >(null);
  public rooms$: BehaviorSubject<Room[]> = new BehaviorSubject<Room[]>(null);
  public locationdescriptions$: BehaviorSubject<LocationDescription[]> =
    new BehaviorSubject<LocationDescription[]>([]);

  // current value objects for navigation
  public currentLocationDescription$: BehaviorSubject<LocationDescription> =
    new BehaviorSubject<LocationDescription>(null);
  public currentLocationDescriptionRoom$: BehaviorSubject<Room> =
    new BehaviorSubject<Room>(null);
  public currentLocationDescriptionItemGroup$: BehaviorSubject<ItemGroup> =
    new BehaviorSubject<ItemGroup>(null);

  public userlog$: BehaviorSubject<UserLog[]> = new BehaviorSubject<UserLog[]>(
    []
  );

  // =======================

  constructor(
    private logger: Logger,
    private messageService: MessageService,
    private storage: StorageMap,
    private dataService: DataService,
    private router: Router,
    private autService: AuthService
  ) {
    this.logger.info("StateStore - constructor");
    this.debugFlag$.next(localStorage.getItem("debug") == "true");
  }

  public initialize(): Subscription {
    // what with multisession / tabs ????
    // initialize
    // - on reload (!!!)
    // - on application start (???) what with expired auth???
    // - on trigger from application =>!!!Q

    this.logger.info("*** StateStore - initialize");
    this.showProgressbar = false; // initialize progressbar DO NOT SET to True because then the logic is broken!!!
    // initialize the online state
    this.unsub(this.sub_io);
    this.sub_io = this.getIsOnlineFromBackend();
    return this.sub_io;
  }

  public getIsOnlineFromBackend(): Subscription {
    //this.logger.info("!!! ping 1");
    return this.dataService.checkIsOnline().subscribe(
      (res) => {
        // this.logger.info("!!! ping 2");

        this.isOnlineFlag = res;
        this.initializeAccourdingToOnlineState();
      },
      (error: any) => {
        // this.logger.info("!!! ping 3");

        // ex: when no connection to api
        this.isOnlineFlag = false;
        this.initializeAccourdingToOnlineState();

        this.messageService.showMessage("working offline");
        this.showProgressbar = false;
        return false;
      }
    );
  }
  private initializeAccourdingToOnlineState() {
    if (this.isOnlineFlag) {
      this.logger.info("*** StateStore - isOnlineFlag");
      if (this.autService.isAuthenticated()) {
        this.logger.info("*** StateStore - isAuthenticated");
        this.messageService.showMessage("synchroniseren gegevens");
        this.messageService.showProgressSpinner();
        this.logUser("synchroniseren gegevens");

        this.handleOnlineSyncProcessLocationDesciptions();

        // these are standing stand alone, oke to load them on initialize
        this.getDataFromBackend_RoomPrototypes();
        this.getDataFromBackend_ItemGroupPrototypes();
      }
    } else {
      // OFFLINE OPERATIONAL DATA PERSENT
      this.messageService.showWarning("working offline");
      this.getStateFromLocalStorage_ALL();
    }
  }

  private handleOnlineSyncProcessLocationDesciptions() {
    // done...
    // this.clearState();

    // retrieve state from indexed db
    this.unsub(this.sub_ls_gld);
    this.sub_ls_gld =
      this.getStateFromLocalStorage_LocationDescriptions().subscribe((res) => {
        if (res) {
          this.logger.info("LocationDescriptions => from local storage");
          this.logger.info(res);

          // if res null there is not local data present
          //this.locationdescriptions$.next(res as LocationDescription[]); /// !!!!
          let locationDescriptionsToSend: LocationDescription[] =
            res as LocationDescription[];
          // here we could show these to the user... but there is still a retrieval pending
          let tasks$: Observable<any>[] = [];
          locationDescriptionsToSend.forEach((locationDescription) => {
            // TODO check if is modified, if not than do not send !!!!
            this.logger.info(
              "*** " +
                locationDescription.Id +
                " = " +
                locationDescription.DateSync +
                locationDescription.DateModified
            );
            //console.log(locationDescription);

            if (
              locationDescription.IsNewSyncFlag == true ||
              new Date(locationDescription.DateSync) <=
                new Date(locationDescription.DateModified)
            ) {
              this.logger.info("*** CHANGED  ");
              tasks$.push(
                this.dataService.updateCurrentLocationDescription(
                  locationDescription,
                  this.userlog$.value
                )
              );
            } else {
              this.logger.info("*** no change  ");
            }
          });

          console.log("##############################");
          console.log(tasks$.length);

          if (tasks$.length == 0) {
            this.logUser("geen veranderingen ...");
            this.getDataFromBackend_LocationDescriptions();
          } else {
            forkJoin(tasks$).subscribe((results) => {
              this.messageService.showMessage(
                "syncronisatie gegevens voltooid"
              );
              this.logUser("datagevens verzenden voltooid ...");
              this.getDataFromBackend_LocationDescriptions();
            });
          }
        } else {
          this.logUser("geen lokale data aanwezig...");
          this.getDataFromBackend_LocationDescriptions();
        }
      });
  }

  private isOperationalLocalDataPresent(): boolean {
    if (this.rooms$.value == null) {
      return false;
    }
    return true;
  }

  // this is
  private getStateFromLocalStorage_ALL() {
    // get all data from local storage, if all executed, do qual check
    this.unsub(this.sub_ls_gld);
    this.sub_ls_gld =
      this.getStateFromLocalStorage_LocationDescriptions().subscribe(
        (res) => {
          if (res) {
            this.locationdescriptions$.next(res as LocationDescription[]);
          }
        },
        (error: any) => {
          this.logUser("Error");
          this.messageService.showError("Error");
        }
      );

    this.unsub(this.sub_ls_grp);
    this.sub_ls_grp = this.getStateFromLocalStorage_RoomPrototypes();

    this.unsub(this.sub_ls_gip);
    this.sub_ls_gip = this.getStateFromLocalStorage_ItemGroupPrototypes();
  }

  private getStateFromLocalStorage_LocationDescriptions(): Observable<
    LocationDescription[]
  > {
    return this.storage.get("location-descriptions").pipe(
      map((res) => {
        return res as LocationDescription[];
      })
    );
  }
  private getStateFromLocalStorage_RoomPrototypes(): Subscription {
    return this.storage.get("room-prototypes").subscribe(
      (res) => {
        if (res) {
          this.rooms$.next(res as Room[]);
        } else {
          this.messageService.showWarning("RoomPrototypes not present");
          this.router.navigateByUrl("/pages/offline-nodata");
        }
      },
      (error: any) => {
        this.messageService.showError("Error");
        this.logger.error(error);
      }
    );
  }
  private getStateFromLocalStorage_ItemGroupPrototypes(): Subscription {
    return this.storage.get("itemgroup-prototypes").subscribe((res) => {
      if (res) {
        this.itemGroups$.next(res as ItemGroup[]);
      } else {
        this.messageService.showWarning("ItemGroupPrototypes not present");
        this.router.navigateByUrl("/pages/offline-nodata");
      }
    });
  }
  //-//-//-//-//-//-//-//-//

  recalculateRoomSortOrderCurrentLocationDescription() {
    let sortIndex = 1;
    this.currentLocationDescription$.value.Rooms.forEach((room) => {
      room.SortOrder = sortIndex++;
    });
  }
  recalculateItemGroupSortOrderCurrentLocationDescriptionRoom() {
    let sortIndex = 1;
    this.currentLocationDescriptionRoom$.value.ItemGroups.forEach(
      (itemGroup) => {
        itemGroup.SortOrder = sortIndex++;
      }
    );
  }

  //-//-//-//-//-//-//-//-//
  // State loading using Backend calls
  public getDataFromBackend_LocationDescriptions() {
    this.logger.info(
      "*** StateStore - getDataFromBackend_LocationDescriptions"
    );

    this.logUser("data ophalen van server ...");

    this.unsub(this.sub_ds_gld);
    this.sub_ds_gld = this.dataService.getLocationDescriptions().subscribe(
      (locationDescriptions: LocationDescription[]) => {
        if (locationDescriptions) {
          this.logger.info(
            "getLocationDescriptions res => data opgehaald van server ..."
          );
          this.logger.info(locationDescriptions);
          this.logUser("data opgehaald van server ...");

          // show location descriptions to the user
          this.locationdescriptions$.next(locationDescriptions);
          this.messageService.hideProgressSpinner();

          // store the location descriptions retrieved from backend to the local storage
          this.unsub(this.sub_ls_sld);
          this.sub_ls_sld =
            this.setToLocalStorage_LocationDescriptions(locationDescriptions);
        } else {
          this.messageService.showError("ERROR Unexpected State: code 188");
          this.logUser("ERROR Unexpected State: code 188 ...");
        }

        this.showProgressbar = false; // this postition is not exact correct ....
      },
      (error: any) => {
        this.messageService.showError("Error");
        this.logger.error(error);
      }
    );
  }

  private getDataFromBackend_RoomPrototypes() {
    this.logger.info("*** StateStore - getDataFromBackend_RoomPrototypes");
    this.logUser("kamer templates ophalen");

    this.unsub(this.sub_ds_grp);
    this.sub_ds_grp = this.dataService.getRoomPrototypes().subscribe(
      (rooms: Room[]) => {
        this.rooms$.next(_.orderBy(rooms, "Name", "asc")); // this order by can be done in backend!!
        this.setToLocalStorage_State_Rooms();
        this.messageService.showMessage("kamer templates geladen");
        this.logUser("kamer templates geladen");
      },
      (error: any) => {
        this.messageService.showError("Error");
        this.logger.error(error);
      }
    );
  }

  private getDataFromBackend_ItemGroupPrototypes() {
    this.logger.info("*** StateStore - getDataFromBackend_ItemGroupPrototypes");
    this.logUser("optiegroepen ophalen");

    this.unsub(this.sub_ds_gip);
    this.sub_ds_gip = this.dataService.getItemGroupPrototypes().subscribe(
      (itemGroups: ItemGroup[]) => {
        this.itemGroups$.next(_.orderBy(itemGroups, "Id", "asc")); // this order by can be done in backend!!
        this.setToLocalStorage_State_ItemGroups();
        this.messageService.showMessage("optiegroepen laden");
        this.logUser("optiegroepen geladen");
      },
      (error: any) => {
        this.messageService.showError("Error");
        this.logger.error(error);
      }
    );
  }
  // ... End State loading using Backend calls
  //-//-//-//-//-//-//-//-//
  public setToLocalStorage_LocationDescriptions(
    locationdescriptions: LocationDescription[]
  ): Subscription {
    this.logger.info("*** StateStore - setToLocalStorage_LocationDescriptions");
    this.logUser("data lokaal opslaan (plaatsbeschrijvingen)...");

    return this.storage
      .set("location-descriptions", locationdescriptions)
      .subscribe(
        (res) => {
          this.messageService.showSave();
          this.logUser("data lokaal opgeslagen, compleet...");
        },
        (error: any) => {
          this.logUser("ERROR data lokaal opgeslagen, niet compleet...");
          this.messageService.showError("Error");
          this.logger.error(error);
        }
      );
  }

  /// ========== SECTION Setting state to storage  ==========
  public setToLocalStorage_State_LocationDescription(): Subscription {
    this.logUser("data status lokaal opslaan (plaatsbeschrijvingen)...");

    if (this.currentLocationDescription$.value) {
      this.currentLocationDescription$.value.DateModified =
        moment().toISOString(true);
    }

    return this.storage
      .set("location-descriptions", this.locationdescriptions$.value)
      .subscribe(
        (res) => {
          this.messageService.showSave();
          this.logUser("data status lokaal opgeslagen, compleet...");
        },
        (error: any) => {
          this.logUser("ERROR data lokaal opgeslagen, niet compleet...");
          this.messageService.showError("Error");
          this.logger.error(error);
        }
      );
  }
  // this is for lock and unlock
  public setToLocalStorage_State_LocationDescription_and_reload() {
    return this.storage
      .set("location-descriptions", this.locationdescriptions$.value)
      .subscribe(
        (res) => {
          this.messageService.showSave();
          this.logUser("data status lokaal opgeslagen, compleet...");
          window.location.reload();
        },
        (error: any) => {
          this.logUser("ERROR data lokaal opgeslagen, niet compleet...");
          this.messageService.showError("Error");
          this.logger.error(error);
        }
      );
  }
  public setToLocalStorage_State_Rooms(): Subscription {
    this.logUser("data status lokaal opslaan (kamers)...");
    return this.storage.set("room-prototypes", this.rooms$.value).subscribe();
  }
  public setToLocalStorage_State_ItemGroups(): Subscription {
    this.logUser("data status lokaal opslaan (item groepen)...");

    return this.storage
      .set("itemgroup-prototypes", this.itemGroups$.value)
      .subscribe();
  }
  /// ========== SECTION Setting state to storage  ==========

  public addLocationDescription(locationDescription: LocationDescription) {
    this.locationdescriptions$.value.push(locationDescription);
    this.currentLocationDescription$.next(locationDescription);
    //    console.log(this.locationdescriptions$.value);
  }
  //  //  //  //  //  //
  //  HOUSEKEEPING ;-) //
  clearState() {
    this.logger.info("*** StateStore - clearState");
    this.itemGroups$ = new BehaviorSubject<ItemGroup[]>(null);
    this.rooms$ = new BehaviorSubject<Room[]>(null);
    this.locationdescriptions$ = new BehaviorSubject<LocationDescription[]>([]);

    // current value objects for navigation
    this.currentLocationDescription$ = new BehaviorSubject<LocationDescription>(
      null
    );
    this.currentLocationDescriptionRoom$ = new BehaviorSubject<Room>(null);
    this.currentLocationDescriptionItemGroup$ = new BehaviorSubject<ItemGroup>(
      null
    );
  }
  clearLog() {
    this.userlog$ = new BehaviorSubject<UserLog[]>([]);
  }

  logUser(messageLine: string) {
    this.logger.info("log: " + messageLine);
    this.userlog$.value.push({
      message: messageLine,
      sort: this.userlog$.value.length + 1,
    });
    this.userlog$.next(_.orderBy(this.userlog$.value, ["sort"], ["desc"]));
  }

  clearStore() {
    this.logger.info("*** StateStore - clearStore");

    this.clearState();

    this.storage.clear().subscribe((res) => {
      this.messageService.showMessage("clearStore");
      this.clearState();
    });
  }
  ngOnDestroy(): void {
    this.logger.info("*** StateStore - ngOnDestroy");
    for (const subscription of this.subs) {
      subscription.unsubscribe();
    }
    this.cleanup_subs();
  }
  // unsubscribe if exists
  unsub(sub: Subscription) {
    if (sub) {
      sub.unsubscribe();
    }
  }
  private cleanup_subs() {
    this.unsub(this.sub_ls_gld);
    this.unsub(this.sub_ls_sld);
    this.unsub(this.sub_ls_gip);
    this.unsub(this.sub_ls_grp);
    this.unsub(this.sub_io);
    this.unsub(this.sub_ds_gip);
    this.unsub(this.sub_ds_grp);
    this.unsub(this.sub_ds_gld);
  }
}
