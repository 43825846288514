import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { SwUpdate } from "@angular/service-worker";
import { Subscription } from "rxjs/internal/Subscription";
import { environment } from "src/environments/environment";
import { NetworkService } from "./services-old/network.service";
import { Logger } from "./shared/logger/logger";
import { ServiceWorkerUpdateService } from "./shared/service-worker/update-service";
import { StateStore } from "./shared/state/app.state-store";

@Component({
  selector: "app-root",
  templateUrl: "./app.container.html",
})
export class ContainerComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  //constructor(private logger: Logger, private networkService: NetworkService) {
  constructor(
    private title: Title,
    private logger: Logger,
    private serviceWorkerUpdateService: ServiceWorkerUpdateService,
    private stateStore: StateStore
  ) {
    this.title.setTitle("Era-Nobis - Plaatsbeschijvingen");
    this.logger.info("*** ContainerComponent => contructor");
    //this.logger.ais_logPageView("initialize front-end ");
    //this.logger.ais_event("Initialize front-end ");
  }

  ngOnInit() {
    this.serviceWorkerUpdateService.initialize();
    this.stateStore.initialize();
  }
  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
