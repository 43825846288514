import { AppToolbarComponent } from "./components/app-toolbar/app-toolbar.component";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatIconModule } from "@angular/material/icon";
import { MatModule } from "./mat/mat.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbar, MatToolbarModule } from "@angular/material/toolbar";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DialogYesNoComponent } from "./components/dialog-yes-no/dialog-yes-no.component";
import { DialogInputValueComponent } from "./components/dialog-input-value/dialog-input-value.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { SpinnerComponent } from './components/spinner/spinner.component';
//
// Only the modules that are realy needed for al submodules, keep the footprint as small as posible
//
@NgModule({
  declarations: [AppToolbarComponent, DialogYesNoComponent, SpinnerComponent],
  imports: [CommonModule, HttpClientModule, MatModule, RouterModule],
  exports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatModule,
    AppToolbarComponent,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
  ],
})
export class SharedModule {}
