import { Injectable, OnDestroy } from "@angular/core";
import { _MatSlideToggleRequiredValidatorModule } from "@angular/material/slide-toggle";
import { Router } from "@angular/router";
import { OAuthService, TokenResponse } from "angular-oauth2-oidc";
import { promise } from "protractor";
import { from, Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { Logger } from "src/app/shared/logger/logger";
import { User, UserCredentials } from "src/app/shared/models/account.model";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { MessageService } from "src/app/shared/services/message.service";
import { StateStore } from "src/app/shared/state/app.state-store";
import { environment } from "src/environments/environment";

import { authConfig } from "./auth.config";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private superAdminRole: string = "era-nobis.superadmin";

  public claims: any;
  public user: User;
  public hasValidAccessToken: boolean;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private storageService: LocalStorageService, //???? should this use the LocalStorageService
    private logger: Logger,
    private messageService: MessageService
  ) {
    this.oauthService.configure(authConfig);

    this.subscriptions.push(
      from(this.oauthService.loadDiscoveryDocument()).subscribe(
        (res) => {
          // Automatically load user profile
          this.subscriptions.push(
            this.oauthService.events
              .pipe(filter((e) => e.type === "token_received"))
              .subscribe(() => {
                this.subscriptions.push(
                  this.loadUserProfile().subscribe((res) => {
                    this.messageService.showMessage("gebruiker geladen");
                    //  this.router.navigateByUrl("/");
                  })
                  // from(this.oauthService.loadUserProfile()).subscribe(() => {
                  //   this.navigateAfterLogin();
                  //   this.logger.info(
                  //     "*** AuthService - constructor - loadUserProfile"
                  //   );
                  // })
                );
              })
          );

          // Refresh token

          this.oauthService.timeoutFactor = 0.5;
          this.oauthService.setupAutomaticSilentRefresh();
        },
        (err) => this.logger.error("Error loadDiscoveryDocument")
      )
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  login(credentials: UserCredentials): Observable<TokenResponse> {
    this.oauthService.oidc = false;

    if (!this.oauthService.tokenEndpoint) {
      this.messageService.showError(
        "No tokenEndpoint please reload when connected !!!"
      );
    }

    let observableTokenResponse: Observable<TokenResponse> = from(
      this.oauthService.fetchTokenUsingPasswordFlow(
        credentials.Email,
        credentials.Password
      )
    );

    return observableTokenResponse;
  }

  // when the login is oke we need to load user profile
  loadUserProfile(): Observable<any> {
    return from(this.oauthService.loadUserProfile());
  }

  loginWithSSO(): void {
    this.oauthService.oidc = true;
    this.oauthService.initCodeFlow();
  }

  loginWithSSOCallback(): Observable<boolean> {
    this.oauthService.oidc = true;
    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin());
  }

  navigateAfterLogin(): void {
    this.logger.info("*** AuthService - constructor => navigateAfterLogin");

    if (
      this.router.url.startsWith("/sign-in") ||
      this.router.url.startsWith("/auth/sso-callback")
    ) {
      this.logger.info(
        "*** AuthService - constructor => navigateAfterLogin // startswith ...."
      );

      const redirect = this.storageService.get("app-login-redirect");
      if (redirect) {
        this.router.navigateByUrl(redirect);
        this.storageService.remove("app-login-redirect");
      } else {
        this.router.navigateByUrl("/");
      }
    }
  }

  // this is a guard and trigger continuesly
  isAuthenticated(): boolean {
    this.logger.info("*** AuthService - isAuthenticated() ");
    this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
    // if (hasOA) {
    //   this.oauthService.loadUserProfile();
    // }
    var hasUser: boolean = !!this.userProp;
    this.logger.info("*** hasValidAccessToken = " + this.hasValidAccessToken);
    this.logger.info("*** hasUser = " + hasUser);

    return this.hasValidAccessToken && hasUser;
  }

  get userProp(): User {
    this.logger.info("*** AuthService get user()");

    this.claims = this.oauthService.getIdentityClaims();

    this.logger.info2("claims: ", this.claims);

    if (!this.claims) {
      return null;
    }
    this.logger.info(this.claims);

    this.user = new User();
    this.user.Id = this.claims.sub;
    this.user.Email = this.claims.email;
    this.user.Username = this.claims.preferred_username;
    // https://datatracker.ietf.org/doc/html/rfc7519
    this.user.DateNBF = new Date(0); // this way we can set epoch time
    this.user.DateNBF.setUTCSeconds(this.claims.nbf);
    this.user.DateIAT = new Date(0);
    this.user.DateIAT.setUTCSeconds(this.claims.iat);
    this.user.DateEXP = new Date(0);
    this.user.DateEXP.setUTCSeconds(this.claims.exp);
    this.user.DateAuthTime = new Date(0);
    this.user.DateAuthTime.setUTCSeconds(this.claims.auth_time);
    this.user.Name = this.claims.name;

    if (this.claims.role) {
      0;
      if (typeof this.claims.role === "string") {
        this.user.Roles = [this.claims.role];
      } else {
        this.user.Roles = this.claims.role;
      }
    }

    if (this.claims.permission) {
      if (typeof this.claims.permission === "string") {
        this.user.Permissions = [this.claims.permission];
      } else {
        this.user.Permissions = this.claims.permission;
      }
    }

    return this.user;
  }

  get access_token(): string {
    return this.oauthService.getAccessToken();
  }

  hasRole(role: string): boolean {
    return this.user.Roles.includes(role);
  }

  hasPermission(permission: string): boolean {
    // Superadmin
    if (this.hasRole(this.superAdminRole)) {
      return true;
    }

    return this.user.Permissions.includes(permission);
  }

  refreshToken(): Observable<TokenResponse> {
    return from(this.oauthService.refreshToken());
  }

  // for offline access we are not logging out forced hard
  logout(): void {
    //if (!this.router.isActive("/sign-in", false)) {
    this.router.navigateByUrl("/sign-in");
    //}
  }
}
