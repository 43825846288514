import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UiSetting } from "src/app/shared/models/ui.settings";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { MessageService } from "src/app/shared/services/message.service";

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private storageService: LocalStorageService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }

    // this.messageService.showError(
    //   "Access denied canActivate IsAuthenticatedGuard"
    // );
    this.storageService.set("app-login-redirect", state.url);
    this.router.navigateByUrl("/login");

    return false;
  }
}

@Injectable({
  providedIn: "root",
})
export class PermissionGuard implements CanActivate {
  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    private storageService: LocalStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.checkPermission(route)) {
      return true;
    }

    // this.messageService.showError("Access denied");

    this.storageService.set("app-login-redirect", state.url);
    this.router.navigateByUrl("/login");

    return false;
  }

  private checkPermission(route: ActivatedRouteSnapshot): boolean {
    let hasPermission = false;

    for (const checkPermission of route.data.permissions) {
      const permissionFound = this.authService.hasPermission(checkPermission);

      if (permissionFound) {
        hasPermission = true;
      } else {
        hasPermission = false;
        break;
      }
    }

    return hasPermission;
  }
}
