import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MessageService } from "../services/message.service";
import { StateStore } from "../state/app.state-store";

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private state: StateStore
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpErrorResponse: any) => {
        let errorMsg = "";
        console.log("*** ERROR intercept ***");
        console.log(httpErrorResponse);

        if (httpErrorResponse.error) {
          console.log("*** ERROR Debug : section Alfa");
          errorMsg = this.parseError(httpErrorResponse);
        } else {
          console.log("*** ERROR Debug : section Beta");
          errorMsg = `Error Code: ${httpErrorResponse.status},  Message: ${httpErrorResponse.message}`;

          if (httpErrorResponse.status == 401) {
            errorMsg = `Server Connectie Offline : ${httpErrorResponse.message}`;
            //this.state.isOnlineFlag$.next(false);
          }

          if (httpErrorResponse.status == 0) {
            errorMsg = `Server Connectie Offline : ${httpErrorResponse.message}`;
            //this.state.isOnlineFlag$.next(false);
          }
        }
        console.error(errorMsg);
        // trow the error message that is captured here
        return throwError(errorMsg);
        // return next.handle(request); // return throwError(errorMsg);
      })
    );
  }

  private parseError(httpErrorResponse: HttpErrorResponse): string {
    let retMessage: string = "";
    if (this.checkIfErrorMessageExists(httpErrorResponse)) {
      console.log("*** ERROR Debug : section Alfa : condition 1");
      retMessage = `Error: ${httpErrorResponse.error.Message}`;
    } else if (this.checkIfErrorErrorExists(httpErrorResponse)) {
      console.log("*** ERROR Debug : section Alfa : condition 2");
      retMessage = this.getUserFriendlyMessage_Error(httpErrorResponse);
    } else if (httpErrorResponse.status == 0) {
      console.log("*** ERROR Debug : section Alfa : condition 3");
      retMessage = `Server Connectie Offline : ${httpErrorResponse.message}`;
    } else {
      console.error("*** ERROR Debug : section Alfa : condition 4");
      console.error(httpErrorResponse);
      retMessage = `Unhandeled exception - section Alfa : condition 4`;
    }

    return retMessage;
  }

  private checkIfErrorErrorExists(httpErrorResponse: HttpErrorResponse) {
    if (
      httpErrorResponse.error &&
      httpErrorResponse.error.error &&
      httpErrorResponse.error.error != ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  private checkIfErrorMessageExists(httpErrorResponse: HttpErrorResponse) {
    if (
      httpErrorResponse.error &&
      httpErrorResponse.error.Message &&
      httpErrorResponse.error.Message != ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getUserFriendlyMessage_Error(httpErrorResponse: HttpErrorResponse) {
    let retMessage: string = "";
    let error = httpErrorResponse.error.error;
    let error_description = httpErrorResponse.error.error_description;
    if (
      error == "invalid_grant" &&
      error_description == "invalid_username_or_password"
    ) {
      retMessage = "  gebruikers naam of wachtwoord niet juist";
    } else {
      retMessage = `Error: ${httpErrorResponse.error.error},  Description: ${httpErrorResponse.error.error_description}`;
    }

    return retMessage;
  }
}
