import { AuthConfig, OAuthModuleConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.sts,
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + "/auth/sso-callback",
  clientId: "era-nobis_app",
  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',
  responseType: "code",
  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  scope: "openid profile email offline_access profile era-nobis_api roles",
  showDebugInformation: !environment.production,
};

export const oauthModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [environment.api],
    sendAccessToken: true,
  },
};
