import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SpinnerComponent } from "../components/spinner/spinner.component";
import { Logger } from "../logger/logger";
import { UiSetting } from "../models/ui.settings";
// import { TranslateService } from "@ngx-translate/core";
// import { Subscription } from "rxjs";
// import { TranslateHelperService } from "./translation-helper.service";

@Injectable({ providedIn: "root" })
export class MessageService {
  constructor(
    private snackBar: MatSnackBar,
    private uiSetting: UiSetting,
    public dialogSpinner: MatDialog,
    private logger: Logger
  ) {}

  showMessage(message: string) {
    this.logger.info("msg: " + message);

    const config = new MatSnackBarConfig();
    config.horizontalPosition = "left";
    config.panelClass = ["snackbar-custom-info-class"];
    config.duration = this.uiSetting.UiSnackbarShowTimeInfo * 1000;
    this.snackBar.open(message, null, config);
    // this.translateService
    //   .get(message)
    //   .subscribe((message) => this.snackBar.open(message, null, config));
  }

  showWarning(message: string) {
    console.warn("msg: " + message);

    const config = new MatSnackBarConfig();
    config.horizontalPosition = "left";
    config.panelClass = ["snackbar-custom-warning-class"];
    config.duration = this.uiSetting.UiSnackbarShowTimeWarning * 1000;
    this.snackBar.open(message, null, config);
    // this.translateService
    //   .get(message)
    //   .subscribe((message) => this.snackBar.open(message, null, config));
  }

  showError(message: string) {
    this.logger.error("msg: " + message);

    const config = new MatSnackBarConfig();
    config.horizontalPosition = "center";
    config.panelClass = ["snackbar-custom-error-class"];
    config.duration = this.uiSetting.UiSnackbarShowTimeError * 1000;
    this.snackBar.open(message, "x", config);
    // this.translateService
    //   .get(message)
    //   .subscribe((message) => this.snackBar.open(message, "x", config));
  }

  // this is for local saving
  showSave() {
    this.logger.info("msg: bewaar");

    const config = new MatSnackBarConfig();
    config.horizontalPosition = "right";
    config.verticalPosition = "bottom";
    config.panelClass = ["snackbar-custom-save-class"];
    config.duration = this.uiSetting.UiSnackbarShowSaveMS;
    this.snackBar.open("bewaar", "x", config);
    // this.translateService
    //  .get(message)
    //  .subscribe((message) => this.snackBar.open(message, "x", config));
  }

  private dialogRefSpinner: any;
  showProgressSpinner() {
    this.logger.info("*** showProgressSpinner");

    this.dialogRefSpinner = this.dialogSpinner.open(SpinnerComponent, {
      width: "700px",
      data: {
        Title: "Vergrendelen",
        Subtitle: "subtitle",
        Icon: "lock",
      },
    });

    // this.dialogRefSpinner.afterClosed().subscribe((result: any) => {
    // });
  }

  hideProgressSpinner() {
    this.dialogSpinner.closeAll();
  }
}
